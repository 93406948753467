// 応募用紙

<template lang="pug">
.group-application-form.text-black

  .about-printing
    | このページを印刷してご利用ください。
    br
    | ※ 印刷の際はヘッダーやフッターを印字しないように設定してください。

  h1.text-2xl.font-bold {{ groupName }} メンバー登録用紙

  h2.text-xl.font-bold &#x2776; 下記のURLにスマートフォンまたはパソコンでアクセスしてください。

  table.url
    tr
      td
        img.inline(:src='qr.main')
      td
        span http://s.nagoyashiyo.or.jp

  h2.text-xl.font-bold &#x2777; 下記のグループコードと、必要事項を入力して登録を完了してください。

  table.code
    tr
      th グループコード
      td {{ groupCode }}

  h2.text-xl.font-bold &#x2778; 入力したメールアドレス宛に、マイページのURLが記載されたメールが届きますので、そのURLにアクセスしてください。

  table.note
    tr
      td(rowspan=2)
        i.el-icon-info
      td(colspan=3)
        | メールが届かない場合は携帯電話会社の迷惑メール設定が有効になっている可能性があります。
        span 「@nagoyashiyo.or.jp」
        | からのメールを受信できるように設定してください。詳細については下記をご覧ください。
    tr
      td
        img.inline(:src='qr.docomo')
        br
        | docomoの方はこちら
      td
        img.inline(:src='qr.au')
        br
        | auの方はこちら
      td
        img.inline(:src='qr.softbank')
        br
        | SoftBankの方はこちら

  h2.text-xl.font-bold &#x2779; マイページが正しく表示されたら登録完了です。

  table.note
    tr
      td
        i.el-icon-info
      td
        | 今後はマイページからアンケートへの回答を行いますので、マイページをお気に入り(ブックマーク)に登録する、または、ホーム画面に追加することをおすすめします。
</template>

<script>
const qr = {
  main: require('@/assets/qr.png'),
  docomo: require('@/assets/qr-docomo.png'),
  au: require('@/assets/qr-au.png'),
  softbank: require('@/assets/qr-softbank.png'),
}

export default {
  name: 'GroupApplicationForm',

  computed: {
    groupName() {
      return this.$route.query.name
    },
    groupCode() {
      return this.$route.query.code
    },
    qr() {
      return qr
    },
  },
}
</script>

<style lang="sass">
@page
  size: A4
  margin: 12.7mm 9.7mm
@media print
  .group-application-form
    .about-printing
      display: none
    h1
      font-size: 26px
      border-top: 2px solid #000
      border-bottom: 2px solid #000
      text-align: center
      line-height: 1.5
    h2
      margin-top: 30px
      font-size: 20px
      text-indent: -20px
      padding-left: 20px
    table.url
      width: 100%
      tr
        td
          padding: 20px 20px 0
          // width: 50%
          vertical-align: middle
          &:first-child
            text-align: right
          img
            width: 120px
          span
            font-weight: bold
            font-size: 20px
    table.code
      width: 60%
      border-collapse: collapse
      margin: 20px auto 0
      tr
        th, td
          border: 2px solid #000
          width: 50%
          padding: 5px
          font-size: 20px
          text-align: center
        td
          font-weight: bold
          letter-spacing: 5px
    table.note
      margin: 20px auto 0
      width: 90%
      border: 1px solid #000
      tr:first-child
        td
          &:first-child
            font-size: 30px
            width: 10%
            text-align: center
            vertical-align: middle
          &:last-child
            width: 90%
            font-size: 14 px
            span
              font-weight: bold
      tr:nth-child(2)
        td
          width: 30%
          text-align: center
          font-size: 14px
          img
            width: 60px
            margin-top: 10px
</style>

<style lang="sass" scoped>
@media screen
  .group-application-form
    background: white
    max-width: 1000px
    margin: 2rem auto
    padding: 1.5rem 1.5rem 10rem

    .about-printing
      background: #fffae1
      padding: 1rem
      border-radius: 10px
      margin-bottom: 1.5rem

    h1
      border-top: 2px solid #000
      border-bottom: 2px solid #000
      text-align: center
    h2
      margin-top: 1.5rem
    table.url
      width: 100%
      tr
        td
          padding: 20px 20px 0
          // width: 50%
          vertical-align: middle
          &:first-child
            text-align: right
          img
            width: 120px
          span
            font-weight: bold
            font-size: 20px
    table.code
      width: 60%
      border-collapse: collapse
      margin: 20px auto 0
      tr
        th, td
          border: 2px solid #000
          width: 50%
          padding: 5px
          font-size: 20px
          text-align: center
        td
          font-weight: bold
          letter-spacing: 5px
    table.note
      margin: 20px auto 0
      width: 90%
      border: 1px solid #000
      tr:first-child
        td
          &:first-child
            font-size: 30px
            width: 10%
            text-align: center
            vertical-align: middle
          &:last-child
            width: 90%
            font-size: 14 px
            span
              font-weight: bold
      tr:nth-child(2)
        td
          width: 30%
          text-align: center
          font-size: 14px
          img
            width: 60px
            margin-top: 10px
</style>
